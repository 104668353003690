







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component({})
export default class FileItem3D extends Vue {
  @Prop({ default: () => {} }) item!: any
  @Prop({ default: '100' }) size!: string
}
