

















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PopoverInput from '@/components/popover-input.vue'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import LsUpload from '@/components/ls-upload.vue'
import FileItem from './file-item.vue'
import {
  apiRecycleBinList,
  apiRecycleBinReset,
  apiRecycleBinDel,
} from '@/api/imagetru3d'
import { RequestPaging } from '@/utils/util'
@Component({
  components: {
    LsPagination,
    PopoverInput,
    LsDialog,
    LsUpload,
    FileItem,
  },
})
export default class RecycleBin extends Vue {
  // @Prop({ default: 'image' }) type!: 'image' | 'video' | 'file'
  @Prop({ default: 'image' }) type!:
    | 'image'
    | 'video'
    | 'interaction'
    | 'opticalField'

  @Prop({ default: '100' }) size!: string
  @Prop({ default: 'popup' }) mode!: 'pages' | 'popup'
  @Prop({ default: 20 }) pageSize!: number
  @Prop() limit!: number
  // data
  name = ''
  moveId = 0
  checkAll = false
  isIndeterminate = false
  currentId = ''
  fileList = []
  groupeLists: any[] = []
  pager = new RequestPaging({ size: this.pageSize })
  selectList: any[] = []

  // 获取每个标签页的类型别名
  get typeValue() {
    switch (this.type) {
      case 'image':
        return 10
      case 'video':
        return 20
      case 'interaction':
        return 30
      case 'opticalField':
        return 40
    }
  }

  @Watch('selectList')
  selectListChange(val: any[]) {
    this.$emit('change', val)
    if (val.length == this.pager.lists.length && val.length !== 0) {
      this.isIndeterminate = false
      this.checkAll = true
      return
    }
    if (val.length > 0) {
      this.isIndeterminate = true
    } else {
      this.checkAll = false
      this.isIndeterminate = false
    }
  }

  //
  get selectStatus() {
    return (id: any) => this.selectList.find((item) => item.id == id)
  }

  //
  getList(): void {
    if (this.mode == 'pages') {
      this.clearSelectList()
    }
    this.pager.request({
      callback: apiRecycleBinList,
      params: {
        type: this.typeValue,
        cid: this.currentId,
        name: this.name,
      },
    })
  }

  // 选中文件 / 取消选中文件
  selectFile(item: any) {
    let index = this.selectList.findIndex((items: any) => items.id == item.id)
    if (index != -1) {
      this.selectList.splice(index, 1)
      return
    }
    if (this.mode == 'popup' && this.selectList.length == this.limit) {
      if (this.limit == 1) {
        this.selectList = []
        this.selectList.push(item)
        return
      }
      this.$message.warning('已达到选择上限')
      return
    }
    this.selectList.push(item)
  }

  // 当页全选 / 取消当页全选
  selectAll(value: boolean) {
    this.isIndeterminate = false
    if (value) {
      this.selectList = [...this.pager.lists]
      return
    }
    this.clearSelectList()
  }

  // 彻底删除
  recycleBinDel(id: any) {
    apiRecycleBinDel({ id }).then((res) => {
      this.$message.success('已彻底删除素材文件')
      this.getList()
    })
  }

  // 移出回收站
  batchRecycleBinReset() {
    let ids = this.selectList.map((item) => item.id)
    apiRecycleBinReset({ ids }).then((res) => {
      this.$message.success('已还原素材文件')
      this.getList()
    })
  }

  // 取消当页全选
  clearSelectList() {
    this.selectList = []
  }

  created() {
    this.getList()
  }
}
